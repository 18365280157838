// ** Redux Imports
import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'
// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import aboutUs from './about_us'
import product from './products'
import banner from './banner'
import wishlist from './wishlist'
import blog from './blog'
import footer from './footer'
import voucher from "./voucher";


const rootReducer = combineReducers({
  toastr: toastrReducer,
  auth,
  navbar,
  layout,
  aboutUs,
  banner,
  product,
  blog,
  wishlist,
  footer,
  voucher,
});

export default rootReducer
