// User registration
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

// User authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const AUTH_LOADING = "AUTH_LOADING";

// User email activation
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";

// Reset password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_CONFIRM_SUCCESS = "RESET_PASSWORD_CONFIRM_SUCCESS";
export const RESET_PASSWORD_CONFIRM_FAIL = "RESET_PASSWORD_CONFIRM_FAIL";

// Check if user is aithenticated
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";

// Load the user
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";

// Google authentication
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";

// Facebook authentication
export const FACEBOOK_AUTH_SUCCESS = "FACEBOOK_AUTH_SUCCESS";
export const FACEBOOK_AUTH_FAIL = "FACEBOOK_AUTH_FAIL";

// About us
export const GET_ABOUT_US = "GET_ABOUT_US";
export const LOADING_ABOUT_US = "LOADING_ABOUT_US";

// Director Message
export const GET_DIRECTOR_MESSAGE = "GET_DIRECTOR_MESSAGE";
export const LOADING_DIRECTOR_MESSAGE = "LOADING_DIRECTOR_MESSAGE";

// About us contact
export const GET_ABOUT_US_CONTACT_LIST = "GET_ABOUT_US_CONTACT_LIST";
export const LOADING_ABOUT_US_CONTACT_LIST = "LOADING_ABOUT_US_CONTACT_LIST";

// About us team
export const GET_ABOUT_US_TEAM_LIST = "GET_ABOUT_US_TEAM_LIST";
export const LOADING_ABOUT_US_TEAM_LIST = "LOADING_ABOUT_US_TEAM_LIST";

// Contact List
export const GET_CONTACT_LIST = "GET_CONTACT_LIST";
export const GET_CONTACT_US = "GET_CONTACT_US";

// Legal Term
export const GET_LEGAL_TERM_LIST = "GET_LEGAL_TERM_LIST";

// TimeLine
export const LOADING_ABOUT_TIMELINE_LIST = "LOADING_ABOUT_TIMELINE_LIST";
export const GET_ABOUT_US_TIMELINE_LIST = "GET_ABOUT_US_TIMELINE_LIST";
// FAQ

// Product
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const LOADING_PRODUCT = "LOADING_PRODUCT";
export const GET_PRODUCT_LIST_BEST_SELLER = "GET_PRODUCT_LIST_BEST_SELLER";
export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE";
export const GET_PRODUCT_ATTRIBUTE = "GET_PRODUCT_ATTRIBUTE";
export const GET_PRODUCT_ATTRIBUTE_ID = "GET_PRODUCT_ATTRIBUTE_ID";

export const GET_PRODUCT_TYPE_DETAILS = "GET_PRODUCT_TYPE_DETAILS";
export const GET_PRODUCT_CONTENT_ATTRIBUTE = "GET_PRODUCT_CONTENT_ATTRIBUTE";
// Reviews
export const GET_PRODUCT_REVIEWS = "GET_PRODUCT_REVIEWS";

// Categories
export const GET_CATEGORIES = "GET_CATEGORIES";

// Brand
export const GET_BRAND = "GET_BRAND";

// Voucher

// Hero Banner
export const LOADING_BANNER = "LOADING_BANNER";
export const HEROS_BANNER = "HEROS_BANNER";

// Feautured Banner
export const LOADING_FEAUTURED_BANNER = "LOADING_FEAUTURES_BANNER";
export const FEAUTURED_BANNER = "FEAUTURES_BANNER";

// Commercial Banner
export const LOADING_COMMERCIAL_BANNER = "LOADING_COMMERCIAL_BANNER";
export const COMMERCIAL_BANNER = "COMMERCIAL_BANNER";

// Partner Banner
export const LOADING_PARTNER_BANNER = "LOADING_PARTNER_BANNER";
export const PARTNER_BANNER = "PARTNER_BANNER";

// Wishlist
export const LOADING_WISHLIST = "ADD_IN_WISHLIST";
export const GET_WISHLIST = "GET_WISHLIST";

// Cart
export const LOADING_CART = "ADD_IN_CART";
export const GET_CARTS = "GET_CART";

// Blog
export const LOADING_BLOG_POSTS = "LOADING_BLOG_POSTS";
export const BLOG_POSTS = "BLOG_POSTS";

//Voucher
export const VERIFY_VOUCHER = "VERIFY_VOUCHER";

export const FOOTER_LIST = "FOOTER_LIST";

export const LOADING_FOOTER = "LOADING_FOOTER";

// Orders
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";

export const GET_ORDER = "GET_ORDER";
export const GET_SERVICE_DETAILS = "GET_SERVICE_DETAILS";
export const GET_QUESTION_BUNDLE_LIST = "GET_QUESTION_BUNDLE_LIST";

export const GET_RECOMMENDED_PRODUCTS = "GET_RECOMMENDED_PRODUCTS";

export const VOUCHER_LIST = "VOUCHER_LIST";
export const VOUCHER_LOADING = "VOUCHER_LOADING";

export const SEND_OFFER_LIST = "SEND_OFFER_LIST";
export const SEND_OFFER_DETAILS = "SEND_OFFER_DETAILS";


export const BANNER_ONE_LIST = "BANNER_ONE_LIST";
export const BANNER_TWO_LIST = "BANNER_TWO_LIST";

export const WEBSITE_INFO = 'WEBSITE_INFO';

export const AUTHORIZED_CATEGORY_LIST = "AUTHORIZED_CATEGORY_LIST";
export const APPOINTMENT_AVAILABILITY_LIST = "APPOINTMENT_AVAILABILITY_LIST";

export const GET_AVAILABLE_DATE = "GET_AVAILABLE_DATE";
export const APPOINTMENT_LISTING = "APPOINTMENT_LISTING";
export const USERS_LIST = "USERS_LIST";


export const STATISTICS = "STATISTICS";
