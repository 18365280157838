import { BLOG_POSTS, LOADING_BLOG_POSTS } from '../types/types'

const initialState = {
  blogPosts: [],
  loading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BLOG_POSTS:
      return { ...state, loading: action.payload || false }
    case BLOG_POSTS:
      return { ...state, blogPosts: action.payload }
    default:
      return state
  }
}
