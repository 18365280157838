import mock from '../mock'
import { paginateArray } from '../utils'

const data = [
  {
    responsive_id: '',
    id: 1,
    avatar: '11.jpg',
    prod_name: "Centrala termica in condensare Buderus Logamax Plus GB162-70 V2 (doar incalzire)",
    category: 'Centrale Termice Pe Gaz',
    price: '150',
    city: 'Krasnosilka',
    start_date: '09/23/2016',
    stock: '300',
    age: '61',
    experience: '1 Year',
    status: 2
  },
  {
    responsive_id: '',
    id: 2,
    avatar: '12.jpg',
    prod_name: "Termosemineu pe peleti Ferroli Mirano Pellet 24 KW",
    category: 'Termoseminee pe peleti',
    price: '7390',
    city: 'Krasnosilka',
    start_date: '09/23/2016',
    stock: '15',
    age: '61',
    experience: '1 Year',
    status: 2
  },
  {
    responsive_id: '',
    id: 3,
    avatar: '13.jpg',
    prod_name: "Servomotoare rotative compacte ESBE Seria ARA600",
    category: 'Servomotoare',
    price: '400',
    city: 'Krasnosilka',
    start_date: '09/23/2016',
    stock: '415',
    age: '61',
    experience: '1 Year',
    status: 2
  },
  {
    responsive_id: '',
    id: 4,
    avatar: '14.jpg',
    prod_name: "Pachet Panou Solar Ferroli Ecotube New 20 + Boiler Bivalent 150 litri ",
    category: 'Panouri Solare',
    price: '6190',
    city: 'Krasnosilka',
    start_date: '09/23/2016',
    stock: '15',
    age: '61',
    experience: '1 Year',
    status: 2
  }
]

mock.onGet('/api/datatablesprod/initial-data').reply(config => {
  return [200, data]
})

mock.onGet('/api/datatables/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.full_name.toLowerCase().includes(queryLowered) ||
      item.post.toLowerCase().includes(queryLowered) ||
      item.email.toLowerCase().includes(queryLowered) ||
      item.age.toLowerCase().includes(queryLowered) ||
      item.salary.toLowerCase().includes(queryLowered) ||
      item.start_date.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
