import { VOUCHER_LIST, VOUCHER_LOADING } from "../types/types";

const initialState = {
  voucherList: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VOUCHER_LOADING:
      return { ...state, loading: action.payload || false };
    case VOUCHER_LIST:
      return { ...state, voucherList: action.payload };
    default:
      return state;
  }
}
