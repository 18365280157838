import {
  GET_CATEGORIES,
  GET_PRODUCT_LIST,
  LOADING_PRODUCT,
  GET_PRODUCT_LIST_BEST_SELLER,
  GET_PRODUCT_TYPE,
  GET_PRODUCT_ATTRIBUTE,
  GET_BRAND,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_TYPE_DETAILS,
  GET_PRODUCT_ATTRIBUTE_ID,
  GET_PRODUCT_REVIEWS,
  GET_PRODUCT_CONTENT_ATTRIBUTE,
  GET_ALL_ORDERS,
  GET_ORDER,
  GET_SERVICE_DETAILS,
  GET_QUESTION_BUNDLE_LIST,
  GET_RECOMMENDED_PRODUCTS,
  SEND_OFFER_LIST,
  SEND_OFFER_DETAILS,
  STATISTICS
} from "../types/types";

const initialState = {
  products: [],
  loading: false,
  categories: {},
  bestSellerProducts: [],
  productType: [],
  productAttribute: [],
  productAttributeId: [],
  brands: [],
  productDetails: {},
  productTypeDetails: {},
  productReviews: [],
  productContentAttributes: [],
  allOrders: [],
  orderPagination: {},
  orderDetail: {},
  serviceDetails: [],
  questionsBundleList: [],
  recommendedProducts: [],
  sendOfferList: [],
  sendOfferPagination: {},
  sendOfferDetails: {},
  statistics:{},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PRODUCT:
      return { ...state, loading: action.payload || false };
    case GET_PRODUCT_LIST:
      return { ...state, products: action.payload };
    case GET_PRODUCT_LIST_BEST_SELLER:
      return { ...state, bestSellerProducts: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_PRODUCT_TYPE:
      return { ...state, productType: action.payload };
    case GET_PRODUCT_ATTRIBUTE:
      return { ...state, productAttribute: action.payload };
    case GET_PRODUCT_ATTRIBUTE_ID:
      return { ...state, productAttributeId: action.payload };
    case GET_BRAND:
      return { ...state, brands: action.payload };
    case GET_PRODUCT_DETAILS:
      return { ...state, productDetails: action.payload };
    case GET_RECOMMENDED_PRODUCTS:
      return { ...state, recommendedProducts: action.payload };
    case GET_PRODUCT_TYPE_DETAILS:
      return { ...state, productTypeDetails: action.payload };
    case GET_PRODUCT_REVIEWS:
      return { ...state, productReviews: action.payload };
    case GET_PRODUCT_CONTENT_ATTRIBUTE:
      return { ...state, productContentAttributes: action.payload };
    case GET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload.data,
        orderPagination: action.payload.pagination,
      };
    case GET_ORDER:
      return {
        ...state,
        orderDetail: action.payload,
      };
    case GET_SERVICE_DETAILS:
      return {
        ...state,
        serviceDetails: action.payload,
      };
    case GET_QUESTION_BUNDLE_LIST:
      return {
        ...state,
        questionsBundleList: action.payload,
      };
    case SEND_OFFER_LIST:
      return {
        ...state,
        sendOfferList: action.payload,
        sendOfferList: action.payload.data,
        sendOfferPagination: action.payload.pagination,
      };
    case SEND_OFFER_DETAILS:
      return {
        ...state,
        sendOfferDetails: action.payload,
      };
    case STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    default:
      return state;
  }
}
