import mock from '../mock'
import { paginateArray } from '../utils'

const data = [
  {
    responsive_id: '',
    id: 1,
    avatar: '8.jpg',
    full_name: "Radu Bogdan",
    post: 'Front End Developer',
    email: 'ribogdan@gmail.com',
    address: 'O strada din Valcea , Rm Valcea, Romania',
    telephone: '0757021351',
    stock: '300',
    age: '61',
    experience: '1 Year',
    status: 2
  },
  {
    responsive_id: '',
    id: 2,
    avatar: '15.jpg',
    full_name: "Radu Gafita",
    post: 'Back End Developer',
    email: 'radugaf@gmail.com',
    address: 'O strada din Bucuresti, Bucuresti, Romania',
    telephone: '05645890564',
    stock: '15',
    age: '61',
    experience: '1 Year',
    status: 2
  },
  {
    responsive_id: '',
    id: 3,
    avatar: '2.jpg',
    full_name: "Andreea Mihalache",
    post: 'Marketing',
    email: 'mihaela@gmail.com',
    address: 'O strada din Braila, Romania',
    telephone: '0753213518',
    stock: '15',
    age: '61',
    experience: '1 Year',
    status: 2
  },
  {
    responsive_id: '',
    id: 4,
    avatar: '6.jpg',
    full_name: "Stefabua Claudia",
    post: 'Vanzari',
    email: 'stefania@gmail.com',
    address: 'O strada din Iasi, Romania',
    telephone: '05623186513',
    stock: '15',
    age: '61',
    experience: '1 Year',
    status: 2
  }
]

mock.onGet('/api/datatablesusers/initial-data').reply(config => {
  return [200, data]
})

mock.onGet('/api/datatables/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.full_name.toLowerCase().includes(queryLowered) ||
      item.post.toLowerCase().includes(queryLowered) ||
      item.email.toLowerCase().includes(queryLowered) ||
      item.age.toLowerCase().includes(queryLowered) ||
      item.salary.toLowerCase().includes(queryLowered) ||
      item.start_date.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
