import {
  LOADING_WISHLIST,
  GET_WISHLIST,
  LOADING_CART,
  GET_CARTS,
  VERIFY_VOUCHER,
} from "../types/types";

const initialState = {
  loading: false,
  wishlists: [],
  carts: [],
  voucher: {},
  localStorageCart: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_WISHLIST:
      return { ...state, loading: action.payload || false };
    case GET_WISHLIST:
      return { ...state, wishlists: action.payload };

    case LOADING_CART:
      return { ...state, loading: action.payload || false };
    case GET_CARTS:
      return { ...state, carts: action.payload };
    case VERIFY_VOUCHER:
      return { ...state, voucher: action.payload };
    default:
      return state;
  }
}
